/*
 * Box de Vídeo
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import FsLightbox from 'fslightbox-react';
import Button from '../Button';
import './styles/video.scss';

class Video extends React.Component{
  constructor(props) {
    super(props);
    
    this.state = {
      togglerSlide: false
    }
  }

  openVideo(){
    this.setState({
      togglerSlide: !this.state.togglerSlide
    });
  }

  render() {
    return (
      <div className={'video clear y' + this.props.year + ' idx' + this.props.index} style={{backgroundImage: `url(${this.props.content.image})`}}>
        {!!this.props.content.video && this.props.content.video !== '' && <button type="button" onClick={(e) => this.openVideo(this.props.content.video)}>Play</button>}
        {!!this.props.content.video && this.props.content.video !== '' && <FsLightbox
          toggler={this.state.togglerSlide}
          sources={[this.props.content.video]}
          disableLocalStorage={true}
        />}
        <div className="content">
          <div dangerouslySetInnerHTML={{__html: this.props.content.text}} />
          {!!this.props.content.link.button ? <Button {...this.props.content} /> : ''}
        </div>
      </div>
    );
  }
}

export default withRouter(Video);
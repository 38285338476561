/*
 * Lista com redes sociais
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import './styles/socialMedia.scss';

class SocialMedia extends React.Component{
  render() {
    return(
      <ul className={'socialMedia ' + this.props.component}>
        <li>
          <a href="https://facebook.com/anagovbr" title="Facebook" className="fb" target="_blank" rel="noopener noreferrer">Facebook</a>
        </li>
        <li>
          <a href="https://twitter.com/anagovbr" title="Twitter" className="tw" target="_blank" rel="noopener noreferrer">Twitter</a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCLLjIf0AjdwQAtp3hDmrhZQ" title="YouTube" className="yt" target="_blank" rel="noopener noreferrer">YouTube</a>
        </li>
        <li>
          <a href="https://br.linkedin.com/company/anagovbr" title="LinkedIn" className="in" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        </li>
        <li>
          <a href="https://instagram.com/anagovbr" title="Instagram" className="ig" target="_blank" rel="noopener noreferrer">Instagram</a>
        </li>
      </ul>
    );
  }
}

export default withRouter(SocialMedia);
/*
 * Header do site
 */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './styles/header.scss';
import logo from '../../assets/img/logo-cma.png';
import newlogo from '../../assets/img/ana-20-anos-new.png'
const { detect } = require('detect-browser');

class Header extends React.Component {
  componentDidMount(props) {
    const browser = detect();
    document.documentElement.classList.add(browser.name);
  }

  render() {
    return (
      <header>
        <h1>
          <Link to="/" title="ANA 20 Anos">
            <div className='img-logo'>
              <img src={logo} alt="ANA 20 Anos" /></div></Link>
        </h1>
      </header>
    );
  }
}

export default withRouter(Header);
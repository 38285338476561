
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './styles/centroMemoria.scss';
import ImgMain from '../../assets/img/img-main.png'

class CentroMemoria extends React.Component {

    render() {
        return (
            <div className={'centroMemoria'}>
                <div className='img-main'>
                    <img src={ImgMain} />
                </div>
            </div>
        );
    }
}

CentroMemoria.propTypes = {
    items: PropTypes.array.isRequired
}

export default withRouter(CentroMemoria);
/*
 * Timeline dos anos na lateral
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import jQuery from 'jquery';
import timeline from "../../utils/timeline.json";
import './styles/listYears.scss';

class ListYears extends React.Component{
  constructor(props) {
    super(props);
    
    this.state = {
      years: []
    };
  }

  getParam(param){
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  }

  verifyYears(){
    let items = timeline;

    if(!!this.getParam('section'))
    {
      items = timeline.filter(x => {
        return x.section.slug === this.getParam('section')
      });
    }

    if(!!items && items.length)
    {
      if(!!this.getParam('type'))
      {
        items = items.filter(x => {
          return x.type === this.getParam('type')
        });
      }

      items = Array.from(new Set(items.map(x => x.year))).map(item => {
        if(item < 2000) return null;
        return item;
      }).filter(it => { return it});
    }

    this.setState({
      years: items
    });

    if(document.querySelectorAll('#listYears').length)
      document.querySelectorAll('#listYears > span')[0].style.top = '37px';

    this.forceUpdate();
  }

  componentDidMount(props){
    this.unlisten = this.props.history.listen((location, action) => {
      window.setTimeout(function(){
        this.verifyYears();
      }.bind(this), 100)
    });

    window.setTimeout(function(){
      this.verifyYears();
    }.bind(this), 100)
  }

  componentWillUnmount(){
    this.unlisten();
  }

  scrollTo(e){
    e.preventDefault();
    jQuery( 'html, body' ).animate({ scrollTop: ( jQuery('#timeline ul.items .y' + jQuery(e.target).attr('data-target')).eq(0).offset().top - document.querySelectorAll('header')[0].clientHeight ) + 5 });
  }

  render() {
    if( !!this.props.match.isExact && this.props.match.path === '/' && this.state.years.length )
      return (
        <section id="listYears">
          <span></span>
          <div>
            <ul>
              {
                this.state.years.map((year, index) => <li key={index}>
                  <button type="button" className={'y' + year + ' title'} data-target={year} onClick={this.scrollTo}>{year}</button>
                </li>)
              }
            </ul>
          </div>
        </section>
      );
    else
      return null;
  }
}

export default withRouter(ListYears);
/*
 * Footer do site
 */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import timeline from "../../utils/timeline.json";
import './styles/footer.scss';

class Footer extends React.Component{
  constructor(props) {
    super(props);

    this.returnTypes = arr => {
      return Array.from(new Set(arr.map(x => x.type))).map(type => {
        if(!!!type) return null;

        let returnType = term => {
          switch(term){
            case 'video':
              return 'Vídeos';
            case 'image':
              return 'Galeria de fotos';
            case 'podcast':
              return 'Podcasts';
            case 'quote':
              return 'Citações';
            case 'text':
              return 'Textos';
            default:
              return null;
          }
        }

        return {
          name: returnType(type),
          type: type
        }
      }).filter(it => { return it});
    }

    this.state = {
      types: [],
      active: !!this.getParam('type') ? this.getParam('type') : 'all',
      button: false
    };

    this.clickButton = this.clickButton.bind(this);
  }

  getParam(param){
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  }

  verifyTypes(){
    if(!!this.getParam('section'))
    {
      let sections = timeline.filter(x => {
        return x.section.slug === this.getParam('section')
      })

      if(!!sections && sections.length)
      {
        let types = this.returnTypes(sections);

        if(!!types && types.length)
        {
          types.unshift(
            {
              name: 'Todos',
              type: 'all'
            }
          );

          this.setState({
            types: types,
            button: false
          });
        }else
          this.setState({
            types: [],
            active: 'all',
            button: false
          });
      }else
        this.setState({
          types: [],
          active: 'all',
          button: false
        });
    }else
    {
      let types = this.returnTypes(timeline);

      types.unshift(
        {
          name: 'Todos',
          type: 'all'
        }
      );

      this.setState({
        types: types,
        button: false
      });
    }

    if( ( !!this.getParam('type') && !this.state.types.filter(x => {
      return x.type === this.getParam('type')
    }).length ) || !this.state.types.filter(t => t.type === this.state.active ).length || !this.getParam('type') )
      this.setState({
        active: 'all'
      });
  }

  componentDidMount(props){
    this.unlisten = this.props.history.listen((location, action) => {
      window.setTimeout(function(){
        this.verifyTypes();
      }.bind(this), 100)
    });

    window.setTimeout(function(){
      this.verifyTypes();
    }.bind(this), 100)
  }

  componentWillUnmount(){
    this.unlisten();
  }

  clickButton(e){
    e.preventDefault();
    this.setState({
      button: !this.state.button
    });
  }

  clickType(type, e){
    this.setState({
      active: type
    });
  }

  render() {
    if( !!this.props.match.isExact && this.props.match.path === '/' && !!this.state.types && this.state.types.length )
      return (
        <footer>
          <div className="center">
            <div id="filterType" className={this.state.button ? 'open' : ''}>
              <button type="button" onClick={this.clickButton} className={this.state.active} title={this.state.types.filter(t => t.type === this.state.active ).length ? this.state.types.filter(t => t.type === this.state.active )[0].name : ''}>{this.state.types.filter(t => t.type === this.state.active ).length ? this.state.types.filter(t => t.type === this.state.active )[0].name : ''}</button>
              <ul className={'qtd-' + this.state.types.length}>
                {
                  this.state.types.map((el, index) => <li className={el.type + (el.type === this.state.active ? ' active' : '')} key={index}>
                    <Link onClick={(e) => this.clickType(el.type, e)} to={'/' + (!!this.getParam('section') ? '?section=' + this.getParam('section') + ( el.type !== 'all' ? '&type=' + el.type : '' ) : ( el.type !== 'all' ? '?type=' + el.type : '' ))} className={el.type} title={el.name}>{el.name}</Link>
                  </li>)
                }
              </ul>
            </div>
          </div>
        </footer>
      );
    else
      return null;
  }
}

export default withRouter(Footer);
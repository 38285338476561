/*
 * Box de texto
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../Button';
import './styles/boxText.scss';

class BoxText extends React.Component{
  render() {
    return (
      <div className={'boxText clear y' + this.props.year + ' idx' + this.props.index + ( !!this.props.component.attribute.type ? ' ' + this.props.component.attribute.type : ( !!this.props.component.attribute.bg && !!!this.props.component.attribute.bg.match(/fff/g) ? ' contentWhite' : '' ) ) } style={{backgroundColor: !!this.props.component.attribute.bg ? this.props.component.attribute.bg : '#fff', border: !!!this.props.component.attribute.bg ? '' : ( !!this.props.component.attribute.bg && !!!this.props.component.attribute.bg.match(/fff/g) ? 'none' : '1px solid #C4C4C4' ) }}>
        <div dangerouslySetInnerHTML={{__html: this.props.content.text}} />
        {!!this.props.content.link.button ? <Button {...this.props.content} /> : ''}
      </div>
    );
  }
}

export default withRouter(BoxText);
/*
 * Carrossel de diretores
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import FsLightbox from 'fslightbox-react';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import './styles/directorsCarousel.scss';

class DirectorsCarousel extends React.Component{
  constructor(props) {
    super(props);
    
    this.state = {
      itemsActual: {},
      imagesActual: [],
      nav1: null,
      nav2: null,
      togglerSlide: false,
      idxSlide: 1
    };
  }

  componentDidMount(props){
    this.setState({
      itemsActual: this.props.items,
      imagesActual: this.props.items.map(x => x.url),
      nav1: this.slider1,
      nav2: this.slider2
    });

    //console.log(this.props, this.state)
  }

  setTogglerSlide = idx => {
    //console.log(idx)
    this.setState({
      idxSlide: idx,
      togglerSlide: !this.state.togglerSlide
    });

    this.forceUpdate();
  }

  render(){
    return (
      <div className={'directorsCarousel'}>
        <div className="theSlider clear">
          <Slider
            asNavFor={this.state.nav2} 
            ref={slider => (this.slider1 = slider)} 
            slidesToShow={1} 
            slidesToScroll={1} 
            arrows={false} 
            fade={true}
          >
            {(!!this.state.itemsActual && this.state.itemsActual.length) ? this.state.itemsActual.map((item, index) => <div key={index}>
              <div className="img">
                <img src={item.url} alt={item.name} onClick={() => this.setTogglerSlide(index + 1)} />
              </div>
              <div className="infos">
                <h4>{item.name}</h4>
                <h5>{item.position}</h5>
                <p>({item.years.map((year, idx) => <span key={idx}>{idx !== 0 ? ', ' + year : year}</span>)})</p>
              </div>
            </div>) : ''}
          </Slider>
          <Slider
            asNavFor={this.state.nav1} 
            ref={slider => (this.slider2 = slider)} 
            slidesToShow={4} 
            slidesToScroll={1} 
            centerMode={false} 
            focusOnSelect={true} 
            swipeToSlide={true} 
            dots={false} 
            infinite={false} 
            vertical={true}
            verticalSwiping={true}
          >
            {(!!this.state.itemsActual && this.state.itemsActual.length) ? this.state.itemsActual.map((item, index) => <div key={index} className="dotImage"><div><img src={item.url} alt={item.name} /></div></div>) : ''}
          </Slider>
        </div>
        {(!!this.state.itemsActual && this.state.itemsActual.length) && 
        <FsLightbox
          toggler={this.state.togglerSlide}
          sources={this.state.imagesActual}
          slide={this.state.idxSlide}
          disableLocalStorage={true}
        />
        }
      </div>
    );
  }
}

DirectorsCarousel.propTypes = {
  items: PropTypes.array.isRequired
}

export default withRouter(DirectorsCarousel);
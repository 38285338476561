/*
 * Botão de seção
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../Button';
import './styles/buttonSection.scss';

class ButtonSection extends React.Component{
  render() {
    return (
      <div className={'buttonSection y' + this.props.year + ' idx' + this.props.index}>
        <Button {...this.props.content} />
      </div>
    );
  }
}

export default withRouter(ButtonSection);
/*
 * Quiz
 */

import React from 'react';
import axios from 'axios';
import SocialMedia from '../SocialMedia';
import './styles/quiz.scss';

class Quiz extends React.Component{
  constructor(props){
    super(props);
    
    this.state = {
      data: {},
      active: 'init',
      itemActual: 0,
      statusForm: null,
      hits: 0,
      itemActive: null
    };

    this.closeButton = this.closeButton.bind(this);
    this.qtdHits = () => {
      if(this.state.hits === 0 && !!this.state.data.finish && !!this.state.data.finish.noHits)
        return this.state.data.finish.noHits;
      else if(this.state.hits === 1 && !!this.state.data.finish && !!this.state.data.finish.oneHit)
        return this.state.data.finish.oneHit;
      else if(!!this.state.data.finish && !!this.state.data.finish.moreHits)
        return this.state.data.finish.moreHits.replace(/\$n/g, this.state.hits)
    }
  };

  closeButton(e){
    document.body.classList.remove('quiz-open');

    this.setState({
      active: 'init',
      itemActual: 0,
      statusForm: null,
      hits: 0,
      itemActive: null
    });
  }

  componentDidMount(props){
    axios.get('/static/quiz.json')
      .then(function(response){
        this.setState({
          data: response.data
        });
      }.bind(this))
      .catch(function(error){
        this.closeButton();
      }.bind(this));
  }

  clickNext(type){
    if( type === 'init' ){
      this.setState({
        active: 'form',
        itemActual: 0,
        statusForm: null,
        hits: 0,
        itemActive: null
      });
    }else if( type === 'form' )
    {
      if(this.state.statusForm === 'selected')
      {
        if(this.state.itemActive === this.state.data.items[this.state.itemActual].valid)
          this.setState({
            statusForm: 'resultQuestion',
            hits: this.state.hits + 1
          });
        else
          this.setState({
            statusForm: 'resultQuestion'
          });
      }else if(this.state.statusForm === 'resultQuestion')
      {
        if( this.state.data.items.length === (this.state.itemActual + 1) )
          this.setState({
            active: 'finish',
            itemActual: 0,
            statusForm: null,
            itemActive: null
          });
        else
          this.setState({
            itemActual: this.state.itemActual + 1,
            statusForm: null,
            itemActive: null
          });
      }
    }
  }

  selectOption(index){
    if(this.state.statusForm === 'resultQuestion') return null;
    
    this.setState({
      statusForm: 'selected',
      itemActive: index
    });
  }

  render(){
    return (
      <div id="quiz">
        <div>
          <h2>Quiz!</h2>
          <button type="button" title="Fechar" onClick={(e) => this.closeButton(e)}>Fechar</button>

          {this.state.active === 'init' ? <div className="init">
            {!!this.state.data.init && !!this.state.data.init.title ? <h3 dangerouslySetInnerHTML={{__html: this.state.data.init.title}} /> : ''}
            <button type="button" className="continue" onClick={(e) => this.clickNext('init', e)}>Começar</button>
          </div> : '' }

          {this.state.active === 'form' ? <div className={'form ' + this.state.statusForm}>
            <h3>{this.state.data.items[this.state.itemActual].question}</h3>
            <ul>
              {
                this.state.data.items[this.state.itemActual].options.map((el, index) => <li key={index}>
                  <button type="button" onClick={(e) => this.selectOption(index, e)} className={(( this.state.itemActive === 0 || !!this.state.itemActive ) && this.state.itemActive === index ? 'selected' : '' ) + ' ' + (this.state.data.items[this.state.itemActual].valid === index ? 'valid' : '' )}>{el}</button>
                </li>)
              }
            </ul>
            {!!this.state.statusForm ? <button type="button" className="continue" onClick={(e) => this.clickNext('form', e)}>{this.state.statusForm === 'selected' ? 'Confirmar' : 'Continuar'}</button> : ''}
          </div> : '' }

          {this.state.active === 'finish' ? <div className="finish">
            {!!this.state.data.finish && !!this.state.data.finish.title ? <h3 dangerouslySetInnerHTML={{__html: this.state.data.finish.title}} /> : ''}
            <h4 className={'hit-' + this.state.hits} dangerouslySetInnerHTML={{__html: this.qtdHits()}} />
            
            <div>
              <h5>Compartilhe com seus amigos</h5>
              <SocialMedia component="quiz" />
            </div>
          </div> : '' }
        </div>
      </div>
    );
  }
}

export default Quiz;
/*
 * Sidebar do site
 */

import React from "react";
import { Link, withRouter } from "react-router-dom";
import timeline from "../../utils/timeline.json";
import Quiz from "../Quiz";
import SocialMedia from "../SocialMedia";
import "./styles/sidebar.scss";
import logoAnaPng from "../../assets/img/logo-ana.png";
import logoAnaWebp from "../../assets/img/logo-ana.webp";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sections: Array.from(new Set(timeline.map((x) => x.section.slug)))
        .map((slug) => {
          if (!slug || slug === "all" || slug === "capacitacao") return null;
          return {
            name: timeline.find((t) => t.section.slug === slug).section.name,
            slug: slug,
          };
        })
        .filter((it) => it)
        .sort((a, b) => {
          if (
            (a.slug === "regulacao-e-gestao-de-recursos-hidricos" &&
              b.slug === "rede-hidrometeorologica-nacional") ||
            a.slug < b.slug
          )
            return -1;
          if (a.slug > b.slug) return 1;
          return 0;
        }),
    };
  }

  openQuiz(e) {
    e.preventDefault();
    if (window.innerWidth < 768) {
      if (!!e.target.parentElement.classList.contains("showText")) {
        document.body.classList.add("quiz-open");
        e.target.parentElement.classList.remove("showText");
      } else e.target.parentElement.classList.add("showText");
    } else {
      e.target.parentElement.classList.remove("showText");
      document.body.classList.add("quiz-open");
    }

    if (
      document.querySelectorAll("#sidebar")[0].classList.contains("open-menu")
    )
      document.querySelectorAll("#sidebar")[0].classList.remove("open-menu");
  }

  openMenu(e) {
    e.preventDefault();
    if (!!e.target.parentElement.classList.contains("open-menu"))
      e.target.parentElement.classList.remove("open-menu");
    else e.target.parentElement.classList.add("open-menu");

    if (
      document
        .querySelectorAll("#sidebar div.quiz > a")[0]
        .classList.contains("showText")
    )
      document
        .querySelectorAll("#sidebar div.quiz > a")[0]
        .classList.remove("showText");
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (
        document.querySelectorAll("#sidebar").length &&
        document.querySelectorAll("#sidebar")[0].classList.contains("open-menu")
      )
        document.querySelectorAll("#sidebar")[0].classList.remove("open-menu");

      if (
        document.querySelectorAll("#sidebar div.quiz > a").length &&
        document
          .querySelectorAll("#sidebar div.quiz > a")[0]
          .classList.contains("showText")
      )
        document
          .querySelectorAll("#sidebar div.quiz > a")[0]
          .classList.remove("showText");
    });

    if (
      document.querySelectorAll("#sidebar").length &&
      document.querySelectorAll("#sidebar")[0].classList.contains("open-menu")
    )
      document.querySelectorAll("#sidebar")[0].classList.remove("open-menu");

    if (
      document.querySelectorAll("#sidebar div.quiz > a").length &&
      document
        .querySelectorAll("#sidebar div.quiz > a")[0]
        .classList.contains("showText")
    )
      document
        .querySelectorAll("#sidebar div.quiz > a")[0]
        .classList.remove("showText");
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    if (!!this.props.match.isExact && this.props.match.path === "/")
      return (
        <section id="sidebar">
          <button
            type="button"
            className="onlyMobo"
            onClick={(e) => this.openMenu(e)}
          >
            Menu
          </button>

          <div>
            <a
              href="https://www.ana.gov.br/"
              title="Agência Nacional de Águas"
              className="ana onlyDesk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={window.Modernizr.webp ? logoAnaWebp : logoAnaPng}
                alt="Agência Nacional de Águas"
              />
            </a>

            <h2>
              Explore nossa <span>linha do tempo</span>
            </h2>
            <nav className="clear">
              <ul>
                <li
                  className={
                    this.props.location.pathname === "/" &&
                      this.props.location.search === ""
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/" title="Todos">
                    Todos
                  </Link>
                </li>
                <li>
                  <Link to="/interna/centro-memoria" title="Centro de Memórias">Centro de Memórias</Link>
                </li>
                {this.state.sections.map((el, index) => (
                  <li
                    className={
                      this.props.location.pathname === "/" &&
                        this.props.location.search.match("section=" + el.slug)
                        ? "active"
                        : ""
                    }
                    key={index}
                  >
                    <Link to={"/?section=" + el.slug} title={el.name}>
                      {el.name}
                    </Link>
                  </li>
                ))}
                <li
                  className={
                    this.props.location.pathname === "/" &&
                      this.props.location.search.match("section=capacitacao")
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/?section=capacitacao" title="Capacitação">
                    Capacitação
                  </Link>
                </li>
                <li>
                  <Link to="/interna/galeria-de-fotos" title="Galeria de fotos">
                    Galeria de fotos
                  </Link>
                </li>
                <li>
                  <Link
                    to="/interna/galeria-de-videos"
                    title="Galeria de vídeos"
                  >
                    Galeria de vídeos
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="quiz clear">
              <a href="#a" className="clear" onClick={(e) => this.openQuiz(e)}>
                <h2>Quiz!</h2>
                <p>Teste seus conhecimentos sobre a história da ANA!</p>
              </a>
              <Quiz />
            </div>
            <div className="social clear">
              <SocialMedia component="sidebar" />
            </div>
          </div>
        </section>
      );
    else return null;
  }
}

export default withRouter(Sidebar);

/*
 * Carrossel de vídeos
 */


import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import FsLightbox from 'fslightbox-react';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import './styles/videosCarousel.scss';

class VideosCarousel extends React.Component{
  constructor(props) {
    super(props);
    
    this.state = {
      currentVideo:'',
      itemsActual: [],
      videosActual: [],
      nav1: null,
      nav2: null,
      togglerSlide: false,
      idxSlide: 1,
      load:false,
    };
  }

  componentDidMount(props){
    this.setState({
      itemsActual: this.props.items,
      videosActual: this.props.items.map(x => x.url),
      currentVideo: this.props.items[0],
      update:false,
      load:true,
      nav1: this.slider1,
      nav2: this.slider2
    });

    
  }

  setTogglerSlide = idx => {
    this.setState({
      idxSlide: idx,
      togglerSlide: !this.state.togglerSlide
    });

    this.forceUpdate();
  }

  handlerVideo = item =>{
    this.setState({
      currentVideo:item,
      update:true
    })
  }

  render(){
    return (
      <div className={'videosCarousel'}>
        <div className="theSlider clear">
          <div className="main-video" condition={this.state.load}>
          
            <h2>{this.state.currentVideo.title}</h2>
            <div className="videoWrapper">
              <iframe width="560" height="315" src={`https://www.youtube.com/embed/${this.state.currentVideo.videoId}?autoplay=${this.state.update}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>              
            </div>
           
          </div>

          <div className="list-video">
            {this.state.itemsActual.map((item, index) => <div className="item" onClick={() => this.handlerVideo(item)} key={index}>
                <div class="image">
                    <img src={`https://img.youtube.com/vi/${item.videoId}/maxresdefault.jpg`} width="150" />
                </div>
                <h3>{item.title}</h3>
            </div>)}
          </div>
        </div>
      </div>
    );
  }
}

VideosCarousel.propTypes = {
  items: PropTypes.array.isRequired
}

export default withRouter(VideosCarousel);
/*
 * Carrossel de fotos
 */

import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import jQuery from 'jquery';
import FsLightbox from 'fslightbox-react';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import './styles/collectionCarousel.scss';

class CollectionCarousel extends React.Component{
  constructor(props) {
    super(props);
    
    this.state = {
      itemsSelect: Array.from(new Set(this.props.items.map(x => x.ID))).map(ID => {
        if(!ID) return null;

        return {
          title: this.props.items.find(t => t.ID === ID ).title,
          ID: ID
        }
      }).filter(it => { return it}).sort((a, b) => {
        if(a.ID < b.ID) return -1;
        if(a.ID > b.ID) return 1;
        return 0;
      }),
      itemsSelected: {
        title: '',
        ID: ''
      },
      itemsActual: {},
      imagesActual: [],
      selectOpen: false,
      nav1: null,
      nav2: null,
      togglerSlide: false,
      idxSlide: 1,
      indexSelect: 0
    };
  }

  componentDidMount(props){
    this.setState({
      itemsSelected: this.state.itemsSelect[0],
      itemsActual: this.props.ID === 'galeria-de-fotos' ? this.props.items.filter(it => { return it.ID === this.state.itemsSelect[0].ID})[0] : this.props.items[0],
      imagesActual: this.props.ID === 'galeria-de-fotos' ? this.props.items.filter(it => { return it.ID === this.state.itemsSelect[0].ID})[0].pics.map(x => x.url) : this.props.items[0].pics.map(x => x.url),
      nav1: this.slider1,
      nav2: this.slider2
    });

    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount(){
    document.removeEventListener('click', this.handleClickOutside);
  }

  selectItem(el, index){
    this.setState({
      itemsSelected: el,
      itemsActual: this.props.items.filter(it => { return it.ID === el.ID})[0],
      imagesActual: this.props.items.filter(it => { return it.ID === el.ID})[0].pics.map(x => x.url),
      selectOpen: false,
      indexSelect: index
    });

    this.forceUpdate();
  }

  controlSelect(){
    this.setState({
      selectOpen: !this.state.selectOpen
    });
  }

  returnNodeImages(){
    return this.props.items.filter(it => { return it.ID === this.state.itemsSelected.ID})[0];
  }

  handleClickOutside = event => {
    if(!jQuery(event.target).hasClass('customSelect') && !jQuery(event.target).parents('.customSelect').length)
    {
      this.setState({
        selectOpen: false
      });
    }
  };

  setTogglerSlide = idx => {
    this.setState({
      idxSlide: idx,
      togglerSlide: !this.state.togglerSlide
    });

    this.forceUpdate();
  }

  render(){
    return (
      <div className={'collectionCarousel'}>
        {this.props.ID === 'galeria-de-fotos' && <div className={'customSelect' + (this.state.selectOpen ? ' open' : '')}>
          <p onClick={(e) => this.controlSelect()}>{this.state.itemsSelected.title}</p>
          <ul>
            {this.state.itemsSelect.map((el, index) => <li key={index}><button type="button" onClick={(e) => this.selectItem(el, index, e)}>{el.title}</button></li>)}
            <li><Link to="/interna/diretoria-da-ana">Galeria de diretores</Link></li>
          </ul>
        </div>}
        <div className="theSlider clear">
          <Slider
            asNavFor={this.state.nav2} 
            ref={slider => (this.slider1 = slider)} 
            slidesToShow={1} 
            slidesToScroll={1} 
            arrows={false} 
            fade={true}
          >
            {(!!this.state.itemsActual && !!this.state.itemsActual.pics && this.state.itemsActual.pics.length) ? this.state.itemsActual.pics.map((item, index) => <div key={index}>
              <div className="img">
                {!!item.tooltip && item.tooltip !== '' ? (<div className="tooltip"><i></i><div dangerouslySetInnerHTML={{__html: item.tooltip}} /></div>) : '' }
                <img src={item.url} alt={item.title} onClick={() => this.setTogglerSlide(index + 1)} />
              </div>
              {
                (!!item.title && item.title !== '') || (!!item.desc && item.desc !== '') ? 
                (
                  <div className="infos">
                    {!!item.title && item.title !== '' ? (<h4>{item.title}</h4>) : '' }
                    {!!item.desc && item.desc !== '' ? (<p>{item.desc}</p>) : '' }
                  </div>
                ) : ''
              }
            </div>) : ''}
          </Slider>
          <Slider
            asNavFor={this.state.nav1} 
            ref={slider => (this.slider2 = slider)} 
            slidesToShow={4} 
            slidesToScroll={1} 
            centerMode={false} 
            focusOnSelect={true} 
            swipeToSlide={true} 
            dots={false} 
            infinite={false} 
          >
            {(!!this.state.itemsActual && !!this.state.itemsActual.pics && this.state.itemsActual.pics.length) ? this.state.itemsActual.pics.map((item, index) => <div key={index} className="dotImage"><div><img src={item.url} alt={item.title} /></div></div>) : ''}
          </Slider>
        </div>
        {(!!this.state.itemsActual && !!this.state.itemsActual.pics && this.state.itemsActual.pics.length) && 
        <FsLightbox
          toggler={this.state.togglerSlide}
          sources={this.state.imagesActual}
          slide={this.state.idxSlide}
          disableLocalStorage={true}
          key={this.state.indexSelect}
        />
        }
      </div>
    );
  }
}

CollectionCarousel.propTypes = {
  items: PropTypes.array.isRequired
}

export default withRouter(CollectionCarousel);
/*
 * Timeline do site
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import jQuery from 'jquery';
import timeline from "../../utils/timeline.json";
import Title from '../../components/Title';
import BoxImageDesc from '../../components/BoxImageDesc';
import BoxText from '../../components/BoxText';
import Text from '../../components/Text';
import ButtonSection from '../../components/ButtonSection';
import Video from '../../components/Video';
import './styles/timeline.scss';

class Timeline extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      sections: [],
      years: [],
      yLoop: 0
    };
  }

  getParam(param){
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  }

  verifyTimeline(){
    let sections = timeline,
        sectionsCopy = [];

    if(!!this.getParam('section'))
    {
      sections = timeline.filter(x => {
        return x.section.slug === this.getParam('section')
      });
    }
    
    if(!!sections && sections.length)
    {
      if(!!this.getParam('type'))
      {
        sections = sections.filter(x => {
          return x.type === this.getParam('type')
        });
      }
    }

    if( !sections.filter(x => {
      return x.component.name === 'Title'
    }).length )
    {
      let yLoop = 0;

      sections.forEach((it, idx) => {
        if( !!it.year && it.year >= 2000 && it.year !== yLoop )
        {
          yLoop = it.year;

          sectionsCopy.push({
            "year": it.year,
            "type": null,
            "component": {
              "name": "Title",
              "attribute": null
            },
            "section": {
              "name": "all",
              "slug": "all"
            },
            "content": {
              "link": {
                "external": null,
                "target": "",
                "text": "",
                "button": null
              },
              "text": "",
              "image": "",
              "video": ""
            }
          });
        }

        sectionsCopy.push(it);
      });
    }else
      sectionsCopy = sections;

    this.setState({
      sections: sectionsCopy
    });

    this.forceUpdate();
    window.scrollTo(0, 0);
  }

  verifyYears(){
    let items = timeline;

    if(!!this.getParam('section'))
    {
      items = timeline.filter(x => {
        return x.section.slug === this.getParam('section')
      });
    }
    
    if(!!items && items.length)
    {
      if(!!this.getParam('type'))
      {
        items = items.filter(x => {
          return x.type === this.getParam('type')
        });
      }

      items = Array.from(new Set(items.map(x => x.year))).map(item => {
        return 'y' + item;
      }).filter(it => { return it});
    }

    this.setState({
      years: items
    });
  }

  componentDidMount(props){
    window.addEventListener('scroll', function(a){
      let _scrollY = jQuery(window).scrollTop() + (window.innerHeight / 2);

      if(!!document.body.classList.contains('timeline') && document.querySelectorAll('#listYears').length)
      {
        this.state.years.forEach(year => {
          if(!!document.querySelectorAll('#timeline ul.items .' + year).length)
            document.querySelectorAll('#timeline ul.items .' + year).forEach(item => {
              if( _scrollY >= item.offsetTop && _scrollY < ( item.offsetTop + item.clientHeight ) && !!document.querySelectorAll('#listYears > div > ul li button.' + year).length ) {
                //   document.querySelectorAll('#listYears > span')[0].style.top = ( document.querySelectorAll('#listYears > div > ul li button.' + year)[0].getBoundingClientRect().top - 5 ) + 'px';
                document.querySelectorAll('#listYears > span')[0].style.top = ( document.querySelectorAll('#listYears > div > ul li button.' + year)[0].getBoundingClientRect().top - 5 ) + document.querySelector('#listYears').scrollTop + 'px';
              }
            });
        });
      }
    }.bind(this));

    this.unlisten = this.props.history.listen((location, action) => {
        this.verifyTimeline();
        this.verifyYears();
    });

    window.setTimeout(function(){
      this.verifyTimeline();
      this.verifyYears();
    }.bind(this), 100)
  }

  componentWillUnmount(){
    this.unlisten();
  }

  getComponent(item, index){
    switch(item.component.name){
      case "Title":
      return <Title {...item} index={index} />

      case "BoxImageDesc":
      return <BoxImageDesc {...item} index={index} />

      case "BoxText":
      return <BoxText {...item} index={index} />

      case "Text":
      return <Text {...item} index={index} />

      case "ButtonSection":
      return <ButtonSection {...item} index={index} />

      case "Video":
      return <Video {...item} index={index} />

      default:
      break;
    }
  }

  render() {
    return (
      <main id="timeline" className="clear">
        <Helmet>
          <body className="timeline" />
        </Helmet>

        <div className="center">
          <h2>COMO A HISTÓRIA <br /> COMEÇOU</h2>

          <ul className="items clear">
            { this.state.sections.map( ( item, index ) => <li key={index} className="clear">{ this.getComponent(item,index) }</li>) }
          </ul>
        </div>
      </main>
    );
  }
}

export default withRouter(Timeline);
/*
 * Páginas internas do site
 */

import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import GalleryDefaultCarousel from "../../components/GalleryDefaultCarousel";
import CollectionCarousel from "../../components/CollectionCarousel";
import DirectorsCarousel from "../../components/DirectorsCarousel";
import VideosCarousel from "../../components/VideosCarousel";
import CentroMemoria from "../../components/CentroMemoria";
import "./styles/innerPage.scss";

class InnerPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      new: {
        title: "",
        content: "",
        images: [],
      },
      togglerSlide: false,
      idxSlide: 0,
    };
  }

  getItem() {
    window.scrollTo(0, 0);
    axios
      .get("/static/internas/" + this.props.match.params.id + ".json")
      .then(
        function (response) {
          console.log(response);
          this.setState({
            new: response.data,
          });
        }.bind(this)
      )
      .catch(
        function (error) {
          this.props.history.push("/404");
        }.bind(this)
      );
  }

  componentDidMount() {
    this.getItem();
  }

  componentDidUpdate(a) {
    if (a.match.params.id !== this.props.match.params.id) this.getItem();
  }

  setTogglerSlide = (idx) => {
    this.setState({
      idxSlide: idx,
      togglerSlide: !this.state.togglerSlide,
    });
  };

  render() {
    return (
      <main id="innerPage" className="clear">
        <Helmet>
          <title>{this.state.new.title}</title>
          <body className={"innerPage " + this.state.new.ID} />
        </Helmet>

        <div className="center clear">
          <div className="top clear">
            <Link to="/" title="Voltar">
              Voltar
            </Link>
            <h2>{this.state.new.title}</h2>
          </div>

          {!!this.state.new.images &&
            !!this.state.new.images.length &&
            this.state.new.ID !== "galeria-de-fotos" &&
            this.state.new.ID !== 'centro-memoria' &&
            this.state.new.ID !== "premios" &&
            this.state.new.ID !== "galeria-de-videos" &&
            this.state.new.ID !== "diretoria-da-ana" && (
              <GalleryDefaultCarousel
                ID={this.state.new.ID}
                items={this.state.new.images}
              />
            )}

          <div
            className={`content clear ${this.state.new.ID}`}
            dangerouslySetInnerHTML={{ __html: this.state.new.content }}
          />

          {!!this.state.new.images &&
            !!this.state.new.images.length &&
            (this.state.new.ID === "galeria-de-fotos" ||
              this.state.new.ID === "premios") && (
              <CollectionCarousel
                ID={this.state.new.ID}
                items={this.state.new.images}
              />
            )}
          {!!this.state.new.images &&
            !!this.state.new.images.length &&
            this.state.new.ID === "diretoria-da-ana" && (
              <DirectorsCarousel
                ID={this.state.new.ID}
                items={this.state.new.images}
              />
            )}
          {!!this.state.new.images &&
            !!this.state.new.images.length &&
            this.state.new.ID === 'centro-memoria' &&
            <CentroMemoria
              ID={this.state.new.ID}
              items={this.state.new.images} />}
          {!!this.state.new.videos &&
            !!this.state.new.videos.length &&
            this.state.new.ID === "galeria-de-videos" && (
              <VideosCarousel
                ID={this.state.new.ID}
                items={this.state.new.videos}
              />
            )}
        </div>
      </main>
    );
  }
}

export default withRouter(InnerPage);

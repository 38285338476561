import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './assets/sass/general.scss';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import ListYears from './components/ListYears';
import Timeline from './containers/Timeline';
import InnerPage from './containers/InnerPage';
import Page404 from './containers/Page404';
import Footer from './components/Footer';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <BrowserRouter>
    <Helmet titleTemplate="%s - ANA 20 Anos" defaultTitle="ANA 20 Anos">
      <meta name="description" content="ANA 20 Anos - Description" />
    </Helmet>
    <Header />
    <Sidebar />
    <ListYears />
    <Switch>
      <Route exact path="/" component={Timeline} />
      <Route exact path="/interna/:id" component={InnerPage} />
      <Route path="" component={Page404} />
    </Switch>
    <Footer />
  </BrowserRouter>
, document.getElementById('ana-20-anos'));

serviceWorker.unregister();
/*
 * Box de imagem com texto descritivo
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../Button';
import './styles/boxImageDesc.scss';

class BoxImageDesc extends React.Component{
  render() {
    return (
      <div className={'boxImageDesc clear y' + this.props.year + ' idx' + this.props.index + ' ' + this.props.component.attribute.direction} style={!!this.props.component.attribute.height ? {height: this.props.component.attribute.height} : null}>
        {this.props.component.attribute.direction === 'right' || this.props.component.attribute.direction === 'bottom' ? <div className="img" style={{backgroundImage: `url(${this.props.content.image})`}} /> : ''}
        <div className="content">
          <div dangerouslySetInnerHTML={{__html: this.props.content.text}} />
          {!!this.props.content.link.button ? <Button {...this.props.content} /> : ''}
        </div>
        {this.props.component.attribute.direction === 'left' ? <div className="img" style={{backgroundImage: `url(${this.props.content.image})`}} /> : ''}
      </div>
    );
  }
}

export default withRouter(BoxImageDesc);
/*
 * Título dos anos
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import './styles/title.scss';

class Title extends React.Component{
  render() {
    return (
      <h3 className={'title y' + this.props.year + ' idx' + this.props.index}>{this.props.year}</h3>
    );
  }
}

export default withRouter(Title);
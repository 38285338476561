/*
 * Botão
 */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './styles/button.scss';

class Button extends React.Component{
  render() {
    if(!!this.props.link.button){
      if(!!this.props.link.external)
        return ( <a className="button" href={this.props.link.target} title={this.props.link.text !== '' ? this.props.link.text : this.props.text } target="_blank" rel="noopener noreferrer">{this.props.link.text !== '' ? this.props.link.text : this.props.text }</a> );
      else
        return ( <Link to={'/interna/' + this.props.link.target} title={this.props.link.text !== '' ? this.props.link.text : this.props.text } className="button">{this.props.link.text !== '' ? this.props.link.text : this.props.text }</Link> );
    }else
      return( <span className="button">{this.props.link.text !== '' ? this.props.link.text : this.props.text }</span> );
  }
}

export default withRouter(Button);
/*
 * Texto
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import './styles/text.scss';

class Text extends React.Component{
  render() {
    return (
      <div className={'text y' + this.props.year + ' idx' + this.props.index}>
        {this.props.year < 2000 ? <h3>{this.props.year}</h3> : ''}
        <div className="content" dangerouslySetInnerHTML={{__html: this.props.content.text}} />
      </div>
    );
  }
}

export default withRouter(Text);